/* Common Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app {
  min-height: 100vh;
}

/* Dark Mode Styles */
body.dark, .app.dark {
  background-color: #121212;
  color: #f7f7f7;
}

/* Light Mode Styles */
body.light, .app.light {
  background-color: #ffffff;
  color: #333333;
}

/* Spinner CSS */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.shrink {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  animation: none;
}

/* Header CSS */
.header {
  background-color: #2e2c3c;
  padding: 20px;
  text-align: center;
  position: relative;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: #ffd700;
  font-size: 2.5rem;
  margin-left: 20px;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 3s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Main and Spotlight CSS */
.App {
  text-align: center;
}

main {
  padding: 20px;
}

.spotlight {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.spotlight h2 {
  color: #e91e63;
}

.spotlight p {
  color: #555;
}

:root {
  --background-light: #ffffff;
  --text-light: #333333;
  --background-dark: #1a1a1a;
  --text-dark: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.light {
  background-color: var(--background-light);
  color: var(--text-light);
}

body.dark {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  padding-top: 60px;
  flex: 1;
}


:root {
  --primary-color: #6a5acd;
  --secondary-color: #4ecdc4;
  --background-light: #ffffff;
  --background-dark: #121212;
  --text-light: #333333;
  --text-dark: #f0f0f0;
  --card-light: #f0f4f8;
  --card-dark: #1e1e1e;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app.light {
  --primary-color: #6a5acd;
  --secondary-color: #4ecdc4;
  --text-color: var(--text-light);
  --background-color: var(--background-light);
  --card-background: var(--card-light);
}

.app.dark {
  --primary-color: #8a7aed;
  --secondary-color: #5eddd4;
  --text-color: var(--text-dark);
  --background-color: var(--background-dark);
  --card-background: var(--card-dark);
}

.app {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.main-content {
  flex: 1;
  padding-top: 60px;
}

/* Custom cursor styles */
.custom-cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: transform 0.2s ease;
}

.app.light .custom-cursor {
  background: radial-gradient(circle, rgba(106,90,205,1) 0%, rgba(78,205,196,1) 100%);
}

.app.dark .custom-cursor {
  background: radial-gradient(circle, rgba(138,122,237,1) 0%, rgba(94,221,212,1) 100%);
}

/* Global styles */
button {
  cursor: none;
}

a {
  cursor: none;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .app {
    padding: 10px;
  }
  
  .navbar {
    flex-direction: column;
  }
  
  .main-content {
    padding-top: 100px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .app {
    padding: 20px;
  }
}

@media (min-width: 1025px) {
  .app {
    padding: 30px;
  }
}