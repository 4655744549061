@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #6a5acd;
  --secondary-color: #4ecdc4;
  --background-light: #f0f4f8;
  --background-dark: #1a202c;
  --text-light: #2d3748;
  --text-dark: #e2e8f0;
  --card-light: #ffffff;
  --card-dark: #2d3748;
  --accent-color: #ff6b6b;
  --success-color: #48bb78;
  --warning-color: #f6ad55;
  --error-color: #f56565;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.light {
  --primary-color: #6a5acd;
  --secondary-color: #4ecdc4;
  --text-color: var(--text-light);
  --background-color: var(--background-light);
  --card-background: var(--card-light);
}

body.dark {
  --primary-color: #8a7aed;
  --secondary-color: #5eddd4;
  --text-color: var(--text-dark);
  --background-color: var(--background-dark);
  --card-background: var(--card-dark);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.main-content {
  flex: 1;
  padding-top: 60px;
}

button, input[type="submit"] {
  cursor: pointer;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

p {
  line-height: 1.6;
}

input, textarea, select {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

/* Custom cursor styles */
.custom-cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: transform 0.2s ease;
}

body.light .custom-cursor {
  background: radial-gradient(circle, rgba(106,90,205,1) 0%, rgba(78,205,196,1) 100%);
}

body.dark .custom-cursor {
  background: radial-gradient(circle, rgba(138,122,237,1) 0%, rgba(94,221,212,1) 100%);
}

/* Accessibility improvements */
:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}
